import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api_url, credentials } from './api_variables.js'

export const disclaimersApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${api_url}/disclaimers`,
        credentials
    }),
    endpoints: builder => ({
        generalDisclaimers: builder.query({
            query: planId => `/general/${planId}`
        }),
        lineupDisclaimers: builder.query({
            query: planId => `/lineup/${planId}`
        }),
        selectedLineupDisclaimers: builder.query({
            query: planId => `/lineup-selected/${planId}`
        }),
        performanceDisclaimers: builder.query({
            query: planId => `/performance/${planId}`
        })
    }),
    reducerPath: 'disclaimers'
})

export const { useGeneralDisclaimersQuery, useLineupDisclaimersQuery, useSelectedLineupDisclaimersQuery, usePerformanceDisclaimersQuery } = disclaimersApi